import React from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
export default class Subscribe extends React.Component {
  state = {
    FNAME: '',
    LNAME: '',
    EMAIL: '',
    mailChimpSignup: false,
    isBusy: false,
  };

  _handleChange = e => {
    console.log({
      [`${e.target.name}`]: e.target.value,
    });
    this.setState({
      [`${e.target.name}`]: e.target.value,
    });
  };

  // 2. via `async/await`
  _handleSubmit = async e => {
    e.preventDefault();
    this.setState({ isBusy: true });

    await addToMailchimp(this.state.EMAIL, this.state)
      .then(({ msg, result }) => {
        this.state.isBusy = false;
        if (result !== 'success') {
          throw msg;
        }
      })
      .catch(err => {
        console.log('err', err);
      });
    this.setState({ mailChimpSignup: true });
  };

  render() {
    return (
      <section id="signup" className="signup-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-12 mx-auto text-center">
              <i className="far fa-paper-plane fa-2x mb-2 text-white"></i>
              <h2 className="text-white mb-5">Subscribe to receive updates!</h2>
              {!this.state.mailChimpSignup && (
                <form
                  className="form-inline d-flex"
                  onSubmit={this._handleSubmit}
                  netlify-honeypot="bot-field"
                >
                  <p class="d-none">
                    <label>
                      Don’t fill this out if you’re human:{' '}
                      <input name="bot-field" />
                    </label>
                  </p>
                  <input
                    type="text"
                    onChange={this._handleChange}
                    className="form-control flex-fill mr-0 mr-sm-2 mb-3 mb-sm-0"
                    required
                    name="FNAME"
                    placeholder="Enter your first name..."
                  />
                  <input
                    type="text"
                    onChange={this._handleChange}
                    required
                    className="form-control flex-fill mr-0 mr-sm-2 mb-3 mb-sm-0"
                    name="LNAME"
                    placeholder="Enter your last name..."
                  />
                  <input
                    type="email"
                    required
                    onChange={this._handleChange}
                    className="form-control flex-fill mr-0 mr-sm-2 mb-3 mb-sm-0"
                    name="EMAIL"
                    placeholder="Enter your email..."
                  />
                  <button
                    type="submit"
                    className={
                      this.state.isBusy
                        ? 'btn-sm btn-primary mx-auto disabled'
                        : 'btn-sm btn-primary mx-auto'
                    }
                  >
                    {this.state.isBusy && (
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                    Subscribe
                  </button>
                </form>
              )}

              {this.state.mailChimpSignup && (
                <div className="text-white">
                  Thanks we have your details. You will receive updates in our
                  next newsletter
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}
