import React from 'react';
import config from '../../config';

export default class SocialLinks extends React.Component {
  state = {
    contactFormSent: false,
    isBusy: false,
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ isBusy: true });
    let contactForm = document.getElementsByName('contact')[0];
    let formData = new FormData(contactForm);

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        console.log('Form successfully submitted');
        this.setState({ contactFormSent: true, isBusy: false });
      })
      .catch(error => alert(error));
  };

  render() {
    return (
      <section className="contact-section bg-black">
        <div className="container">
          <div className="row">
            <div className="col-md-4 mb-3 mb-md-0">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://goo.gl/maps/RRHqS6WP5ZChMwuTA"
              >
                <div className="card py-4 h-100">
                  <div className="card-body text-center">
                    <i className="fas fa-map-marked-alt text-primary mb-2"></i>
                    <h4 className="text-uppercase m-0">Address</h4>
                    <hr className="my-4" />
                    <div className="small text-black-50">{config.address}</div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-md-4 mb-3 mb-md-0">
              <div className="card py-4 h-100">
                <div className="card-body text-center">
                  <i className="fas fa-envelope text-primary mb-2"></i>
                  <h4 className="text-uppercase m-0">Email</h4>
                  <hr className="my-4" />
                  <div className="small text-black-50">
                    <a href={`mailto:${config.email}`}>{config.email}</a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 mb-3 mb-md-0">
              <div className="card py-4 h-100">
                <div className="card-body text-center">
                  <i className="fas fa-address-card text-primary mb-2"></i>
                  <h4 className="text-uppercase m-0">Get in touch</h4>
                  <hr className="my-4" />

                  {!this.state.contactFormSent && (
                    <form
                      name="contact"
                      method="POST"
                      data-netlify="true"
                      onSubmit={this.handleSubmit}
                      netlify-honeypot="bot-field"
                    >
                      <input type="hidden" name="form-name" value="contact" />
                      <p class="d-none">
                        <label>
                          Don’t fill this out if you’re human:{' '}
                          <input name="bot-field" />
                        </label>
                      </p>
                      <div className="form-group">
                        <input
                          required
                          id="name"
                          name="Full Name"
                          className="form-control"
                          placeholder="Enter your name"
                        />
                      </div>

                      <div className="form-group">
                        <input
                          required
                          id="contactnumber"
                          name="Contact Number"
                          className="form-control"
                          placeholder="Enter your contact number"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          required
                          id="emailContact"
                          name="Email Address"
                          type="email"
                          className="form-control"
                          placeholder="Enter your email address"
                        />
                      </div>
                      <button
                        type="submit"
                        disabled={this.state.isBusy}
                        className="btn-sm btn-primary mx-auto"
                      >
                        {this.state.isBusy && (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                        Contact Me
                      </button>
                    </form>
                  )}

                  {this.state.contactFormSent && (
                    <div className="small text-black-50">
                      Thank you for your interest we will be in touch soon.
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* <div className="social d-flex justify-content-center">
          {config.socialLinks.map(social => {
            const { icon, url } = social;
            return (
              <a key={url} href={url} className={`mx-2`}>
                <i className={`fab ${icon}`}></i>
              </a>
            );
          })}
        </div> */}
        </div>
      </section>
    );
  }
}
