import React from 'react';

import Layout from '../components/Layout';
import Scroll from '../components/Scroll';

import config from '../../config';
import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import Subscribe from '../components/Subscribe';
import Header from '../components/Header';

import demoImage2 from '../assets/images/demo-image-02.jpg';
import saltLamp from '../assets/images/bg-saltlamp.jpg';
import bgBracelets from '../assets/images/bg-bracelets.jpg';


export default class IndexPage extends React.Component {
  
    render(){
      return (
        <Layout>
          <Header />

          <header className="masthead">
            <div className="container d-flex h-100 align-items-center">
              <div className="mx-auto text-center">
                <h1 className="mx-auto my-0 text-uppercase">
                  {config.heading}
                </h1>
                <h2 className="text-white-50 mx-auto mt-2 mb-5">
                  {config.subHeading}
                </h2>
                <Scroll type="id" element="about">
                  <a href="#about" className="btn btn-primary">
                    About
                  </a>
                </Scroll>
              </div>
            </div>
          </header>

          <section id="about" className="about-section text-center">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 mx-auto">
                  <h2 className="text-white mb-4">About Us</h2>
                  <p className="text-white-50">
                    <span>
                      Destiny has been a notable presence in Windermere for over
                      ten years, a good place to buy some incense, a
                      dreamcatcher or to just drop in for a chat. We hope to
                      bring that same energy to our growing online community.
                    </span>
                    <br/>
                    <br/>
                    <span>
                      Our purpose is to educate and be educated through books,
                      spiritual tools and the community at large. Every faith,
                      philosophy and religion has wisdom for us to learn from
                      and cultivating that spirit is a priority.
                    </span>
                    <br/>
                    <br/>
                    <span>
                      We hope to reach more people with our message of
                      inclusivity, kindness and love and carry this into the
                      online space in an effort to reach those who are homebound
                      in these chaotic times.
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section id="projects" className="projects-section bg-light">
            <div className="container">
              <div className="row align-items-center no-gutters mb-4 mb-lg-5">
                <div className="col-xl-8 col-lg-7">
                  <h2 className="text-black mb-4">What We Sell</h2>
                  <img
                    className="img-fluid mb-3 mb-lg-0"
                    src={saltLamp}
                    alt=""
                  />
                </div>
                <div className="col-xl-4 col-lg-5">
                  <div className="featured-text text-center text-lg-left">
                    <h4>Salt Lamps</h4>
                    <p className="text-black-50 mb-0">
                      Increase positivity in your space, our salt lamps are natural ionisers and air cleansers. Simply switch it on and enjoy its tranquil glow.
                    </p>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center no-gutters mb-5 mb-lg-0">
                <div className="col-lg-6">
                  <img className="img-fluid" src={bgBracelets} alt="" />
                </div>
                <div className="col-lg-6">
                  <div className="bg-black text-center h-100 project">
                    <div className="d-flex h-100">
                      <div className="project-text w-100 my-auto text-center text-lg-left">
                        <h4 className="text-white">Semi-precious Bracelets</h4>
                        <p className="mb-0 text-white-50">
                          We have a wide range of bracelets to choose from, to suite your indivdual needs. 
                        </p>
                        <hr className="d-none d-lg-block mb-0 ml-0" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center no-gutters">
                <div className="col-lg-6">
                  <img className="img-fluid" src={demoImage2} alt="" />
                </div>
                <div className="col-lg-6 order-lg-first">
                  <div className="bg-black text-center h-100 project">
                    <div className="d-flex h-100">
                      <div className="project-text w-100 my-auto text-center text-lg-right">
                        <h4 className="text-white">And so much more</h4>
                        <p className="mb-0 text-white-50">
                          We also stock essentials oils, incense, Destiny Arch Angel sprays, Tarot cards, spirtuals books 
                          and all manner of healing gifts.
                        </p>
                        <hr className="d-none d-lg-block mb-0 mr-0" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Subscribe />

          <SocialLinks />
          <Footer />
        </Layout>
      );
    }
}
